const notice = document.querySelector('.fixBtn');
const footer = document.querySelector('.footer');

window.addEventListener('scroll', () => {
  if (footer.getBoundingClientRect().top < window.innerHeight) {
    notice.classList.add('is_hide');
  } else {
    notice.classList.remove('is_hide');
  }
});
