import '../scss/index.scss';
import './lozad';
import './aos';
import './header';
import './swiper';
import './dropdown';
import './lowermv';
import './footer';
import MatchHeight from './matchheight.js';

// マッチハイト
new MatchHeight('.js-matchheight', '.swiper-slide');

// スムーススクロール
document.querySelectorAll('a[href^="#"]').forEach(anchor => {
  anchor.addEventListener('click', function (e) {
    e.preventDefault();
    const href = anchor.getAttribute('href');
    const target = document.getElementById(href.replace('#', ''));
    if (target) {
      setTimeout(() => {
        const headerHeight = document.querySelector('header').offsetHeight;
        const targetPosition = target.getBoundingClientRect().top + window.pageYOffset - headerHeight;
        window.scrollTo({
          top: targetPosition,
          behavior: 'smooth'
        });
      }, 0);
    }
  });
});

// ページ外スクロール
// ページが読み込まれたら処理を実行
window.addEventListener('DOMContentLoaded', function() {
  const url = new URL(location.href);
  const hash = url.hash.slice(1);
  const target = document.getElementById(hash);
  if (target) {
    // ページの読み込みが完全に終わるまで少し待つ
    setTimeout(function() {
      const fadeUpAmount = 100; // AOSにてフェードアップする量
      const headerHeight = document.querySelector('header').offsetHeight;
      const top = target.getBoundingClientRect().top + window.pageYOffset - headerHeight - fadeUpAmount;
      console.log(target, target.getBoundingClientRect().top, window.pageYOffset, headerHeight);
      window.scrollTo({
        top: top,
        behavior: 'smooth'
      });
    }, 100); // 100ミリ秒待つ
  }
});

// タブ
document.addEventListener('DOMContentLoaded', function() {
  const tabItems = document.querySelectorAll('.js-tab .js-tabItem');
  const tabContents = document.querySelectorAll('.js-tabArea .js-tabcCntents');
  tabItems.forEach(function(tabItem, index) {
    tabItem.addEventListener('click', function() {
      tabItems.forEach(function(item) {
        item.classList.remove('is-on');
      });
      tabItem.classList.add('is-on');
      tabContents.forEach(function(content, i) {
        content.classList.remove('is-on');
      });
      tabContents[index].classList.add('is-on');
    });
  });
});

// ドロップダウン
document.addEventListener('DOMContentLoaded', function() {
  const pullDownButtons = document.querySelectorAll('.js-pullDown');
  for (const button of pullDownButtons) {
    button.addEventListener('click', function() {
      const content = button.nextElementSibling;
      const parent = button.parentNode;
      // const pullParent = document.querySelectorAll('.js-pullParent');
      content.classList.toggle('is-open');
      button.classList.toggle('is-on');
    });
  }
});
