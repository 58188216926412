import '../scss/index.scss';
import './lozad';
import './aos';
import './header';
import './swiper';
import './dropdown';
import './lowermv';
import MatchHeight from './matchheight.js';

new MatchHeight('.js-matchheight', '.swiper-slide');

document.addEventListener('DOMContentLoaded', function() {
  const tabItems = document.querySelectorAll('.js-tab .js-tabItem');
  const tabContents = document.querySelectorAll('.js-tabArea .js-tabcCntents');
  tabItems.forEach(function(tabItem, index) {
    tabItem.addEventListener('click', function() {
      tabItems.forEach(function(item) {
        item.classList.remove('is-on');
      });
      tabItem.classList.add('is-on');
      tabContents.forEach(function(content, i) {
        content.classList.remove('is-on');
      });
      tabContents[index].classList.add('is-on');
    });
  });
});

document.addEventListener('DOMContentLoaded', function() {
  const pullDownButtons = document.querySelectorAll('.js-pullDown');
  for (const button of pullDownButtons) {
    button.addEventListener('click', function() {
      const content = button.nextElementSibling;
      const parent = button.parentNode;
      // const pullParent = document.querySelectorAll('.js-pullParent');
      content.classList.toggle('is-open');
      button.classList.toggle('is-on');
    });
  }
});
